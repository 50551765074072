import { drizzleReactHooks as drizzleHooks } from "@drizzle/react-plugin";
import * as React from "react";
import Check from "./Check";
import ErrorIcon from "./ErrorIcon";
import Spinner from "./Spinner";

const PROPOSALS = [
  "Nothing",
  "Dog",
  "Cat",
  "Penguin",
  "Monkey",
  "Koala",
  "Rabbit",
  "Panda",
];

function ShowAccount({ account }) {
  return (
    <div className="space-y-2">
      <h2 className="text-gray-800 text-xl font-bold">My Account</h2>
      <p className="text-gray-700 font-mono text-xs sm:text-base">{account}</p>
    </div>
  );
}

function ShowMyTokens({ myTokens }) {
  const { useCacheSend } = drizzleHooks.useDrizzle();
  const { send: resetMyVotes } = useCacheSend("Proposals", "resetMyVotes");

  return (
    <div className="space-y-6">
      <p className="text-gray-800 text-2xl">
        <span className="font-bold">{myTokens}</span> animal tokens
      </p>
      <button
        onClick={() => resetMyVotes()}
        className="motion-reduce:transition-none transition-colors duration-300 ease-in-out bg-indigo-500 hover:bg-red-500 text-white text-lg font-bold px-2 py-1 rounded"
      >
        Reset Votes
      </button>
    </div>
  );
}

function ShowPersonalInfo({ account, myTokens }) {
  return (
    <div className="bg-white p-4 shadow rounded-md space-y-2">
      <ShowAccount account={account} />
      <ShowMyTokens myTokens={myTokens} />
    </div>
  );
}

function AdminInfo({ account }) {
  const [addr, setAddr] = React.useState("");
  const { useCacheSend } = drizzleHooks.useDrizzle();
  const { send: setupStudent } = useCacheSend("Proposals", "setupStudent");

  return (
    <div className="bg-white p-4 rounded-md shadow space-y-6 text-gray-800">
      <h2 className="text-2xl font-bold">Owner's Section</h2>
      <div className="space-y-3">
        <input
          type="text"
          placeholder="Ethereum Address"
          value={addr}
          onChange={(e) => setAddr(e.target.value)}
          className="shadow-inner text-base sm:text-xl px-2 bg-gray-50 rounded-md placeholder-gray-700 font-mono w-full"
        ></input>
        <button
          onClick={() => setupStudent(addr)}
          className="transition-colors duration-300 ease-in-out text-white rounded text-lg shadow font-bold py-1 px-2 bg-indigo-500 hover:bg-blue-400"
        >
          Initialize Student
        </button>
      </div>
    </div>
  );
}

function sortProposals(proposals) {
  const copy = [...proposals];
  copy.sort((a, b) => b.votes - a.votes);
  return copy;
}

function fromTo(from, to) {
  const arr = [];
  for (let i = from; i < to; ++i) {
    arr.push(i);
  }
  return arr;
}

function enoughVotes(myTokens, votes) {
  return myTokens >= 2 * votes + 1;
}

function ShowTransaction({ tid, status }) {
  let placeholder = <Spinner />;
  let color = "text-blue-600";
  if (status === "success") {
    placeholder = <Check />;
    color = "text-green-600";
  } else if (status === "error") {
    placeholder = <ErrorIcon />;
    color = "text-red-600";
  }
  return (
    <li className={`flex space-x-4 items-center ${color} space-y-2`}>
      <div className="w-6">{placeholder}</div>
      <div className="font-bold pb-1">{tid}</div>
    </li>
  );
}

function ShowTransactions({ transactions }) {
  const reversed = [...Object.entries(transactions)];
  reversed.reverse();
  if (reversed.length == 0) {
    return <></>;
  }
  return (
    <ul className="bg-white rounded shadow-md p-4">
      {reversed.map(([tid, { status }]) => {
        console.log("first key", tid);
        return <ShowTransaction key={tid} tid={tid} status={status} />;
      })}
    </ul>
  );
}

function SetButton({ name }) {
  const { useCacheSend } = drizzleHooks.useDrizzle();
  const [voteString, setVoteString] = React.useState("0");
  const { send } = useCacheSend("Proposals", "setVotes");
  const setVotes = () => {
    const int = parseInt(voteString);
    if (isNaN(int)) {
      return;
    }
    send(name, int);
  };

  return (
    <div className="pl-16 flex items-end space-x-4">
      <input
        type="number"
        value={voteString}
        className="w-12 rounded shadow-inner px-1 bg-gray-50"
        onChange={(e) => setVoteString(e.target.value)}
      ></input>
      <button
        className="transition-colors text-white text-sm p-1 font-bold bg-indigo-500 hover:bg-blue-400 rounded"
        onClick={() => setVotes()}
      >
        Set Votes
      </button>
    </div>
  );
}

function Proposals({ myTokens, admin }) {
  const { useCacheCall, useCacheSend } = drizzleHooks.useDrizzle();

  const proposals = useCacheCall(["Proposals"], (call) =>
    PROPOSALS.map((p, i) => ({
      name: p,
      votes: call("Proposals", "proposals", i),
      myVotes: call("Proposals", "myVotes", p),
    }))
  );
  const { send: voteFor } = useCacheSend("Proposals", "voteFor");

  return (
    <ul className="bg-white p-4 rounded shadow-md text-xl text-gray-800">
      {sortProposals(proposals).map(({ name, votes, myVotes }) => (
        <li key={name} className="flex items-center space-x-4">
          <div className="text-2xl w-1/3">{name}</div>
          <div className="flex items-center space-x-4 justify-between">
            <div className="text-gray-700 w-24">
              <span className="font-bold">{votes}</span>{" "}
              {votes == 1 ? "vote" : "votes"}
            </div>
            <div className="flex items-center flex-grow space-x-2">
              {fromTo(0, myVotes).map((i) => (
                <div
                  key={i}
                  className="w-6 h-6 bg-gradient-to-r from-indigo-500 to-blue-500 rounded"
                ></div>
              ))}
              <button
                onClick={() => voteFor(name)}
                disabled={!enoughVotes(myTokens, myVotes)}
                className={`transition-colors border-2 border-dashed w-6 h-6 ${
                  !enoughVotes(myTokens, myVotes)
                    ? "border-red-500"
                    : "border-indigo-500 hover:border-blue-400"
                }`}
              ></button>
            </div>
          </div>
          {admin ? <SetButton name={name} /> : <></>}
        </li>
      ))}
    </ul>
  );
}

export default function App() {
  const { account, transactions } = drizzleHooks.useDrizzleState(
    (drizzleState) => ({
      account: drizzleState.accounts[0],
      transactions: drizzleState.transactions,
    })
  );

  const { useCacheCall } = drizzleHooks.useDrizzle();

  const myTokens = useCacheCall("Proposals", "tokens", account);
  const theOwner = useCacheCall("Proposals", "owner");
  const admin = theOwner === account;

  return (
    <div className="lg:flex flex-wrap space-y-6 lg:space-y-0 lg:space-x-8 mx-auto px-4 sm:px-0 sm:w-10/12">
      <div className="space-y-6">
        {admin ? <AdminInfo account={account} /> : null}
        <ShowPersonalInfo account={account} myTokens={myTokens} />
      </div>
      <div className="flex flex-col lg:flex-grow space-y-2">
        <Proposals myTokens={myTokens} admin={admin} />
        <ShowTransactions transactions={transactions} />
      </div>
    </div>
  );
}
