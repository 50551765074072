import Spinner from "./Spinner";

export default function Loading() {
  return <div className="text-lg text-blue-800 flex flex-col items-center max-auto">
    <Spinner />
    <p>
      Currently loading the DAPP application
      <br />
      If this is taking a while, make sure that MetaMask is using the <b>Ropsten </b>
      network.
    </p>
  </div>
}