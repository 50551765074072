function Nav() {
  return (
    <nav className="bg-gradient-to-r from-indigo-500 to-blue-500 h-16 flex items-center text-white font-bold text-3xl mb-8 shadow-md">
      <div className="mx-8">Animal Vote</div>
    </nav>
  );
}

export default function Layout(props) {
  return (
    <>
      <Nav />
      <main>{props.children}</main>
    </>
  );
}
