import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Layout from "./Layout"
import { Drizzle, generateStore } from "@drizzle/store";
import { drizzleReactHooks as drizzleHooks } from "@drizzle/react-plugin";
import Proposals from "./contracts/Proposals.json";
import Loading from "./Loading";

const options = {
  contracts: [Proposals],
  web3: {
    fallback: {
      type: "ws",
      url: "ws://127.0.0.1:9545",
    },
  },
};

const drizzleStore = generateStore(options);
const drizzle = new Drizzle(options, drizzleStore);

ReactDOM.render(
  <React.StrictMode>
    <Layout>
      <drizzleHooks.DrizzleProvider drizzle={drizzle}>
        <drizzleHooks.Initializer
          // Optional `node` to render on errors. Defaults to `'Error.'`.
          error="There was an error."
          loadingContractsAndAccounts={<Loading />}
          loadingWeb3={<Loading />}
        >
          <App />
        </drizzleHooks.Initializer>
      </drizzleHooks.DrizzleProvider>
    </Layout>
  </React.StrictMode>,
  document.getElementById("root")
);
